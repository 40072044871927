/* Wrapper-Element, das die Preistabelle umschließt */
.stripe-pricing-wrapper {
    display: flex;
    justify-content: center; /* Zentriert den Inhalt horizontal */
    align-items: center; /* Zentriert den Inhalt vertikal */
    margin: 0 auto; /* Optional: Zentriert den Wrapper-Element im Elternelement */
    padding: 20px; /* Ein wenig Platz um den Wrapper */
    box-sizing: border-box;
}

/* Content-Container, der die Preistabelle direkt enthält */
.stripe-pricing-content {
    max-width: 100%; /* Sorgt dafür, dass der Content-Bereich seine maximale Breite nicht überschreitet */
    width: 100%;
    text-align: center;
    /* Weitere spezifische Stile können hier je nach Bedarf hinzugefügt werden */
}

/* Die eigentliche Stripe Preistabelle */
.stripe-pricing-table {
    /* Hier können Sie die Stripe-Preistabelle spezifisch anpassen, falls Stripe dies zulässt */
    width: 100%; /* Sorgt dafür, dass die Preistabelle responsive ist */
}

/* Media Queries für responsives Design, wenn nötig */
@media (max-width: 1024px) {
    .stripe-pricing-wrapper {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .stripe-pricing-wrapper {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .stripe-pricing-content {
        padding: 5px;
    }
}

/* Hier können Sie individuelle Farben, Schriftarten und weitere Stil-Änderungen vornehmen */