.feature-bulletpoint-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: auto;
  }
  
  .feature-bulletpoint-item {
    flex: 0 1 40%; 
    margin-bottom: 40px;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa88;
  }

  .h2-features-und-vorteile {
    font-size: 50px;
    text-align: center;
  }
  
  @media (max-width: 600px) {
    .feature-bulletpoint-item {
      flex: 1 0 100%;
      margin: 10px 0;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  
  .feature-bulletpoint-list-item {
    list-style-type: none; 
    margin-bottom: 20px;
    font-size: 0.8em;
  }
  
  .feature-bulletpoint-list-item::before {
    content: "🔹";
    margin-right: 10px;
  }

.feature-bulletpoint-list-item strong {
  font-weight: bold;
}


  .color-background-bullet {
    background: radial-gradient(circle at 90% 90%, #6D83F2 1%, #d8e3f8 50%);
  }
  .border-bullet {
    border-color: rgb(128, 128, 128) !important;
    border: solid 2px;
    border-radius: 20px !important;
 
  }

  .feature-bulletpoint-item h2 {
    color: #6D83F2;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .feature-bulletpoint-item img {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  