body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;

}

/* Mobile devices (landscape and portrait) ----------- */
@media only screen and (max-width: 768) {
  h1, h2, h3, h4, h5, h6 {
      font-size: 100%; /* adjust as needed */
  }
  body {
    overflow-x: hidden;
}
.app-container {
  margin-top: 5px;
}
}

h1, h2, h3, h4, h5, h6 {
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 100%; /* adjust as needed */
}




.app-container {
  margin: 0 auto;
  margin-top: 65px;
}

