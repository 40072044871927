.main-container {
    
  }
  
  .main-title {
    color: #000;
    margin-bottom: 20px;
  }
  
  .main-description {
    color: #666;
    font-size: 16px;
  }