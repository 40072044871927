.call-to-action-container {
    background: linear-gradient(135deg, #6D83F2 0%, #49C6E5 100%);
    padding: 50px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: white;
}

.call-to-action-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.call-to-action-button {
    font-size: 1.2em !important;
    padding: 15px 30px !important;
    background-color: #6D83F2 !important;;
    color: white!important;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1) ;
    transition: all 0.3s ease;
    text-transform: uppercase;
    border-radius: 40px !important;
}

.call-to-action-button:hover {
    transform: scale(1.05);
    background-color: #49C6E5;
    color: white;
}

.call-to-action-container::before {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    background: rgba(255, 255, 255, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 1;
    animation: blob-rotate 10s linear infinite;
}

@keyframes blob-rotate {
    0% {
        top: -50px;
        left: -50px;
        width: 100px;
        height: 100px;
    }
    100% {
        top: 100%;
        left: 100%;
        width: 300px;
        height: 300px;
    }
}
