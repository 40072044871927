.hero-container {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 10%;
  padding-top: 10%;
}

.text-container,
.image-container {
  flex: 1;
}

.hero-title,
.text-container h1 {
  color: #5c5c5c;
  margin-bottom: 20px !important;
  font-weight: 900 !important;
  font-family: 'Lato', sans-serif;
  line-height: 1.3 !important;
  letter-spacing: -10% !important;
  font-size: 50px;
}

.hero-title span {
  background: linear-gradient(45deg, #6D83F2, #4659a1) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.beta-text {
  font-size: 0.6em;
  color: #6D83F2;
}

.hero-subtitle {
  padding-top: 10px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 40px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4 !important;
}

.hero-button-div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.hero-button,
.hero-ghost-button {
  border-radius: 40px !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  padding: 15px 30px !important;
  box-shadow: 0 3px 5px 2px rgba(105, 105, 105, .3) !important;
}

.hero-button {
  background: #6d83f2 !important;
  color: #fff !important;
}

.hero-ghost-button {
  background: transparent !important;
  border: 2px solid #6D83F2 !important;
  color: #6D83F2 !important;
}

.hero-button:hover {
  background-color: #4659a1 !important;
  transition: background-color 0.3s ease-in-out;
}

.hero-ghost-button:hover {
  border-color: white !important;
  color: white !important;
  background: #6D83F2 !important;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.image-container {
  position: relative;
  overflow: visible;
}

.image-container::before {
  content: "";
  position: absolute;
  top: -40%;
  left: -205%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, #6D83F2 1%, transparent 60%);
  z-index: -1;
  clip-path: circle(50% at 50% 50%);
}

.image-container img {
  max-width: 30vw;
  max-height: 30vw;
  object-fit: cover;
  border: 3px solid #333333;
  border-radius: 20px;
  box-shadow: 10px 10px #4659a1;
}


@media (max-width:610px){
  .hero-container {
    flex-direction: column-reverse;
    align-items: center;
    padding: 10% 10px 0;
  }
  .text-container {
    margin-top: 2px !important;
  }
  .image-container img {
    max-width: 200px;
    max-height: 200px;
    border-radius: 15px;
  }
}

@media (max-width: 500px)  {
  .hero-container {
    flex-direction: column-reverse;
    align-items: center;
    margin: 30px 0 40px;
  }

  .text-container {
    padding: 10px;
    text-align: left !important;
  }

  .text-container h1{
    font-size: 20px !important;
    font-weight: bolder;
  }

  .image-container::before {
    top: 0;
    left: -40%;
    width: 200%;
    height: 100%;
    backdrop-filter: blur(10px);
  }

  .image-container img {
    width: 100%;
    height: 100%;
  }

  .hero-title,
  .text-container h1 {
    font-size: 24px !important;
  }
  .hero-subtitle {
    font-weight: bold !important;
  }
  .hero-button-div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* You had this set to 20px earlier; adjust as needed */
    align-items: center;    /* This will vertically align the items */
    justify-content: center; /* This will horizontally align the items */
  }
  .hero-button, .hero-ghost-button {
    width: 280px; /* Set to the desired width */
    height: 50px; /* Set to the desired height if necessary */
    display: flex;
    justify-content: center;
    align-items: center;
    /* ... other styles ... */
  }
  
}

@media (min-width: 500px) and (max-width:700px){
  .hero-container {
    padding: 3% 10%;
  }
  
  .text-container h1 {
    font-size: 28px !important;
  }
}

@media (min-width: 700px) and (max-width:800px){
  .hero-container {
    padding: 5% 10%;
  }
  
  .text-container h1 {
    font-size: 32px !important;
  }
}