.popover {
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    height: 0;
    visibility: hidden;
  }
  
  .popover.open {
    opacity: 1;
    height: auto; /* Sie können hier einen spezifischen Wert einsetzen, wenn Sie möchten */
    visibility: visible;
    
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  