.header-comp {
  display: flex;
  align-items: center;
  justify-content: center; /* Zentriert alle Elemente, unabhängig vom Scroll-Zustand */
  background-color: #ffffff00!important;
  color: rgb(0, 0, 0)!important;
  top: 0!important;
  z-index: 100!important;
  transition: background-color 0.3s ease!important;
  padding: 0 10px; /* Fügt Padding hinzu, um etwas Abstand zu den Rändern zu haben */
}


.header.scrolled {
  background-color: rgba(253, 253, 253, 0.699);
  color: rgb(0, 0, 0)!important;
}

.calltoaction.header.scrolled {
  color: rgb(248, 248, 248) !important;
  margin: 10px !important;
  padding: 10px 20px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  background-color: #6D83F2 !important;
  letter-spacing: 1px !important;
  border-radius: 40px !important;
}

.header-logo {
  margin-right: auto;
}

/* Keep desktop navigation menu items centered within the header */
.desktop-header-navigation{
  display: flex;
  align-items: center;
  flex-grow: 1; /* Behält die Flexibilität der Elemente bei */
}
.header-menu-desktop {
  white-space: nowrap;
}

.header-logo-image {
  width: 200px;
  height: 35px;
}

.header-navigation-stay {
  display: flex;
  align-items: center;
  margin-left: auto; /* Schiebt die Aktionsbuttons nach rechts */
}

.header-calltoaction-button {
  letter-spacing: 1px !important;
  color: rgb(19, 8, 29) !important;
  margin: 5px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  transition: all 0.3s ease !important;
}

.header-navigation-stay button {
  font-size: 16px;
  background-color: transparent;
  color: #000000;
  border-radius: 50px;
  padding: 10px 20px;
}

.desktop-header-navigation button {
  font-size: 16px;
  color: #5c5c5c;
  border-radius: 50px;
  padding: 10px 20px;
}

.header-menu-icon {
  display: none !important;
  margin-left: auto;
}

@media (max-width: 992px) {
  /* Adjustments for mobile responsiveness */
  .header-menu-icon {
    display: block !important;
  }
  .desktop-header-navigation {
    display: none; /* Hide desktop navigation on smaller screens */
  }
  .header-navigation-stay {
    display: none; /* Hide navigation stay buttons on smaller screens */
  }
}

.header-button {
  font-size: 16px;
}

.list-item-drawer-login {
  padding: 15px !important;
  font-weight: bolder !important;
  background-color: #6D83F2!important;
  border-radius: 10px!important;
  text-align: center;
  box-shadow: 0 0 10px white, 0 0 1px #6D83F2, 0 0 10px #6D83F2, 0 0 10px #6D83F2
}