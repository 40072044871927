.footer-container {
    background-color: #f7f7f7;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.footer-content {
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
}

.footer-section {
    margin-right: 20px;
    padding: 20px;
}

.footer-section.important {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-image {
    max-width: 100px;
    margin: 5px 0;
}

.footer-text {
    color: #333;
    margin-bottom: 10px;
}

.footer-text a {
    color: #5a5a5a;
}

.footer-text a:hover {
    color: #2a2a2a;
}

.footer-title {
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
}

.social-footer-wrapper {
    padding-top: 20px;
    background-color: #f7f7f7;
    text-align: center;
    color: #333;
    padding-bottom: 20px;
}

.social-icon-pinterest, .social-icon-facebook, .social-icon-youtube {
    padding: 5px;
    fill: #333;
    transition: fill 0.3s;
}

.social-icon-pinterest:hover {
    fill: #e60023;
}

.social-icon-facebook:hover {
    fill: #3b5998;
}

.social-icon-youtube:hover {
    fill: #c4302b;
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
    }
}
